@import '../../../../../defaults';

.anw-info-banner {
  background: url('./img/background-info-banner.webp') no-repeat 95% 100%;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    background-position: 100% 100%;
  }
}

.anw-info-banner-sticky {
  background-position: 100% 100%;
  box-shadow: $fixed-bottom-box-shadow;

  .anwaltDeLogo {
    height: 23px;
  }
}
